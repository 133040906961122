import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import '@/assets/css/common.css'
import '@/utils/filters'

Vue.use(Vant)
Vue.config.productionTip = false

// get token from url
const searchParams = new URLSearchParams(window.location.search)
if (searchParams.has('token')) {
  const token = searchParams.get('token')
  window.localStorage.setItem('user-token', token)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
